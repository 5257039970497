@import '~antd/dist/antd.less';

.vendor-txn-row-tooltip {
  .ant-descriptions-view {
    table {
      width: auto;
      .ant-descriptions-item-label {
        color: inherit;
      }
      .ant-descriptions-item-content {
        color: inherit;
      }
    }
  }
  max-width: 500px !important;
}

.price-superscript {
  font-size: smaller !important;
  cursor: pointer;
}

.price-column-header-txn-type-legend {
  font-size: xx-small !important;
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;