@import '~antd/dist/antd.less';

#landing-page {
  background-color: #f0f0f0;
  min-height: 100%;
  #landing-page-content {
    .previous-search-card {
      .ant-btn-link {
        padding: 0px;
      }
    }

    .new-search-card {
      height: 315px;
      @media (max-width: 1440px) {
        height: 259px;
      }
      .disable-search-button-text {
        .ant-typography {
          display: block;
        }
      }
      .ant-card-body {
        height: 100%;
        display: grid;
        place-items: center;
      }
    }
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;