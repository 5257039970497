.ui-chart {
  position: relative;
}
.ui-chart-tooltip {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.recharts-dot {
  opacity: 0;
}

.ui-chart-tooltip-content {
  box-shadow: 8px 7px 21px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 8px 7px 21px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 7px 21px -2px rgba(0, 0, 0, 0.75);
  background-color: #fff;
  padding: 15px 15px;
}

.tooltip-heading {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
.qty,
.date {
  display: flex;
  padding: 5px 0;
  color: #000000;
  font-size: 14px;
  justify-content: space-between;
}
.qty .value {
  padding-left: 10px;
}
.orange {
  color: orange;
}
