@import '~antd/dist/antd.less';
@import '../shared/table.less';

.text-capitalize{
  text-transform: capitalize
}
.source-filter-dropdown .ant-select-item-option-content{
  text-transform: capitalize
}
.source-select .ant-select-selection-item-content{
  text-transform: capitalize
}

#smart-chem-vendors-table {
  .table-styles-mixin();
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;