@import '~antd/dist/antd.less';

#suggested-vendors-table {
  padding-left: @padding-md;
  padding-right: @padding-md;
  padding-bottom: @padding-md;
  background-color: #bcc4ef;
  .ant-table-title {
    padding-left: 0px;
    font-size: @font-size-lg;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #bcc4ef;
    }
  }
  .ant-table-cell {
    border-bottom: none;
    padding: 2px;
    .ant-table-column-sorters{
      padding-left: 2px;
    }
  }
  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: unset;
  }
  .ant-table-row * {
    background-color: #bcc4ef !important;
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;