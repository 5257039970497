@import '~antd/dist/antd.less';

.editable-row .ant-form-item-explain {
  // position: absolute;
  // top: 100%;
}

.add-button-shadow-effect-mixin() {
}

.delete-button-shadow-effect-mixin() {
  border-radius: 50%;
  border: 1px solid @red-5;
  outline: 0;
  box-shadow: 0 0 0 4px rgba(255, 17, 17, 0.2);
}

#search-page {
  .add-new-molecule-button {
    border-radius: 4px;
    border: 1px solid @primary-color;
    outline: 0;
    box-shadow: 0 0 0 4px rgba(24, 144, 255, 0.2);
  }

  #molecule-search-form-table {
    .ant-table-thead {
      .ant-table-cell {
        padding-bottom: 0;
      }
    }
    tr > :first-child {
      padding-left: 0;
    }
    tr > :last-child {
      padding-right: 0;
    }
    th {
      padding-top: 0;
    }
    .ant-table-cell {
      border-bottom: none !important;
  
      .ant-form-item {
        margin-bottom: 0;
      }
  
      .ant-input-number {
        width: 100% !important;
      }
  
      .delete-record-button:focus {
        .delete-button-shadow-effect-mixin
      }
  
      .delete-record-button:hover {
        .delete-button-shadow-effect-mixin
      }
    }
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;