.green-row {
  color: green;
}
.selected-row {
  background-color: #e3e6f7;
}
.selected-row:hover td {
  background-color: #e3e6f7 !important;
}
.month-col {
  min-width: 70px;
}
