@import '~antd/dist/antd.less';

#molecule-review-page {
  .ant-btn-link {
    padding-left: 0px;
  }

  .estimated-savings-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-statistic-title {
      color: black;
      font-weight: bolder;
      text-align: center;
    }
    .ant-statistic-content-value {
      font-weight: bolder;
    }
  }

  .vendors-table-tabs {
    min-height: -webkit-fill-available;
  }

  .ant-tabs-nav {
    padding-left: 100px;
    padding-right: 100px;
    @media (max-width: 1440px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    margin-bottom: 0px;
  }

  .ant-tabs-content-holder {
    padding-top: 16px;
    padding-bottom: 32px;
    padding-left: 100px;
    padding-right: 100px;
    @media (max-width: 1440px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    background-color: #f0f0f0;
    .ant-table-title {
      background-color: #f0f0f0;
      color: black;
      font-weight: bold;
    }
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;