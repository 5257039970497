@import '~antd/dist/antd.less';
@import '../shared/table.less';

#total-identified-vendors-table {
  .table-styles-mixin();

  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: unset;
  }
  .included-vendor-row * {
    background-color: #bcc4ef !important;
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;