.table-styles-mixin() {
  .ant-table-container {
    border: 2px solid #f0f0f0;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #e6e6e6;
    }
  }
  .ant-table-cell {
    border-bottom: none;
  }
  .ant-table-title {
    padding: 0
  }
  .table-custom-comp {
    min-height: 40px;
  }
}