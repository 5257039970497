@import '~antd/dist/antd.less';

#vendor-details-page {
  .ant-descriptions-title {
    margin-bottom: 0px;
    font-size: @font-size-base;
  }
  .ant-descriptions-row {
    th {
      padding: 0;
      .ant-descriptions-item-label {
        font-weight: bold;
      }
    }
  }
  #vendor-txns-table {
    .ant-table-thead {
      .ant-table-cell {
        background-color: #051c2c;
        color: white;
      }
    }
    .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
      background: unset;
    }
    .ant-table-tbody {
      .ant-table-cell {
        border-bottom: 0px;
      }
      .ant-table-row:nth-child(even) * {
        background-color: #f2f2f2 !important;
      }
    }
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;