@import '~antd/dist/antd.less';
body {
  overflow: scroll !important
}

.ant-layout {
  @media (max-width: 1440px) {
    * { 
      font-size: 99%; 
    }
  }
}

.ant-layout-content {
  padding: 25px 100px;
  @media (max-width: 1440px) {
    padding: 25px 50px;
  }
}

.ant-page-header-heading-title {
  font-size: @heading-2-size;
  line-height: ceil($font-size * @line-height-base);
  @media (max-width: 1440px) {
    font-size: @heading-3-size;
    line-height: ceil($font-size * @line-height-base);
  }
}

.ant-typography {
  @media (max-width: 1440px) {
    font-size: 99.5% !important;
  }
}

.ant-page-header {
  padding-left: @padding-xs;
}

.ant-page-header-heading-extra {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-statistic-content-value-decimal {
  font-size: inherit;
}

.ant-statistic-content-suffix {
  font-size: inherit;
}

.ant-button-primary {
  padding-left: @btn-padding-horizontal-lg + 20;
  padding-right: @btn-padding-horizontal-lg + 20;
}

.unit-price-column-header {
  min-width: 160px;
}

.place-child-in-center {
  display: grid;
  place-items: center;
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;