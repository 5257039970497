@import '~antd/dist/antd.less';

#search-results-page {
  .summary-page-header {
    padding-right: 0;
  }
  .ant-tabs-extra-content {
    width: 80%;
    .ant-picker {
      min-width: max-content;
    }
  }
  .ant-tabs-extra-content > :first-child {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;