@import '~antd/dist/antd.less';

#molecule-wise-search-results-list {
  .single-molecule-search-result-row {
    padding-left: 0;
    padding-right: 0;
    .ant-list-item-extra {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .savings-column {
      .ant-statistic-content-suffix {
        margin-left: 0;
      }
    }
    
    #recommeded-suppliers-table {
      .ant-table-cell {
        border-bottom: 0px;
        padding-left: 0px;
        .ant-table-column-sorters{
          padding-left: 0 !important;
        }
      }
    }
    #molecule-review-button {
      padding-left: @btn-padding-horizontal-lg + 20;
      padding-right: @btn-padding-horizontal-lg + 20;
    }
  }
}
@layout-body-background: #fcfcfc;@layout-header-background: #fcfcfc;@layout-header-color: #00263e;@border-color-base: #dcdcdc;@body-background: #fcfcfc;@component-background: #fcfcfc;@heading-color: #041C2C;